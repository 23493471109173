.faq-body {
    display: grid;
}

.row-title {
    font-weight: bolder;
}

.row-content-text {
    text-align: left;
}