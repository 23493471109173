.banner-img {
    height: 13rem;
    padding-inline: 14px;
    object-fit: cover;
    border-radius: 50px;
    padding-bottom: 10px;
}

.banner-img:last-of-type {
    padding-left: 0;
}

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 8rem;
}

.wrapper {
    position: absolute;
    display: flex;
}

section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}
