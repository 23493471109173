.detail-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    align-items: center;
    padding-bottom: 20px;
    justify-content: space-between;
}

.description-container {
    line-height: 5px;
    padding-bottom: 5px;
    text-align: left;
}

.description-container-center {
    line-height: 5px;
    padding-bottom: 5px;
    text-align: center;
    justify-content: center;
}

.detail-container-cneter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;
    justify-content: center;
}

@media (max-width: 900px) {
    .detail-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 100px;
        align-items: center;
        padding-bottom: 20px;
        justify-content: center;
    }

    .description-container {
        line-height: 5px;
        padding-bottom: 5px;
        text-align: center;
        justify-content: center;
    }
}