.mainContainer {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 20px;
}

.nav-body {
    margin-top: 20px;
    margin-bottom: 80px;
}

.logo {
    max-width: 100px;
    height: auto;
}

.navigation {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.nav-bar-container {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.nav-bar {
    display: flex;
}

.nav-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 1.3fr 0.6fr 0.7fr;
    grid-gap: 16px;
    align-items: center;
    justify-items: center;
    font-size: 18px;
    padding-inline-start: 0;
    width: 85%;
}

.nav-list > li {
    display: flex;
}

.nav-list li:first-child {
    margin-left: 0;
}

.nav-list li a {
    text-decoration: none;
    color: #333;
    width: max-content;
}

.nav-list li a:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: #CCA890;
}


.active-tab {
    text-decoration: underline #CCA890;
    text-underline-offset: 5px;
}

.nav-list-icon-mobile {
    display: none;
}

@media (max-width: 1020px) {
    .nav-list {
        display: none;
    }

    .nav-list.is-open {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;
    }

    .nav-bar-container.is-open {
        display: block;
    }

    .nav-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .nav-list-icon-mobile {
        display: block;
        cursor: pointer;
    }

    .active-tab {
        text-decoration: underline #CCA890;
        text-underline-offset: 5px;
    }
}