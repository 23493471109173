.error-page {
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.custom-font {
  font-family: "siteFont", cursive;
  font-size: 36pt;
}
