
.containerStyle {
    position: relative;
    display: inline-block;
}

.imageStyle {
    width: 350px;
    height: 260px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 8px;
}

.titleStyle {
    font-weight: bold;
    padding-bottom: 7px;
}

.descriptionStyle {
    text-align: left;
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    display: block;
    border-radius: 5px;
}

.descStyle {
    font-style: italic;
    font-size: 14px;
}

.footerDescriptionStyle {
    padding-top: 8px;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.footerDescriptionStyle:hover {
    padding-top: 8px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 5px;
    color: #CCA890;
}